import image from './the18.jpeg';
import image3 from './the18_3.jpg';
import image4 from './the18_4.jpg';
import image5 from './the18_5.jpg';
import image6 from './the18_6.jpg';
import image7 from './the18_7.jpeg';
import image8 from './the18_8.jpeg';
import image9 from './the18_9.jpeg';

import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';

import './App.css';

const images = [image, image5, image7, image8, image6, image9, image4];


function App() {
  
  return (
    <div className="App">
      <header className="App-header">
      <h1> The Eighteen </h1>
      <h4> East Nashville</h4>
      </header>

      <div className='Carousel-div'>
        <Carousel>
        {images.map(image => (
        <Carousel.Item key={image}>
          <img
            className="d-block w-100"
            src={image}
            alt=""
          />
        </Carousel.Item>
      ))}

        </Carousel>
        </div>
      
      <p>
        <Button variant="link" href="https://goo.gl/maps/VrrrdkRZCjJQpq77A">
          18 Mcferrin Avenue Nashville, TN 37206
        </Button>
      </p>
      
      <p>
        <Button href="https://www.locale.com/properties/the-eighteen">
          Reserve a Room
        </Button>
      </p>

    </div>
  );
}

export default App;
